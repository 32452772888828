import { isAxiosError } from "axios";
import type { AxiosError } from "axios";

export type ServerError = {
  statusCode: number;
  error: string;
  message: string;
};

const translateMessage: Record<string, string> = {
  "user not found": "Пользователь не найден",
  "invalid password": "Неправильный пароль",
  "field <password> is empty": 'Поле "Пароль" не заполнено',
  "field <login> is empty": 'Поле "Логин" не заполнено',
  "field <email> is empty": 'Поле "E-mail" не заполнено',
  "field <phoneNumber> is empty": 'Поле "Номер телефона" не заполнено',
  "user with this email or login is exist": "Пользователь уже существует",
  "user with this login is exist": "Пользователь с таким логином уже существует",
  "promocode already activated": "Промокод уже активирован",
  "promocode not found": "Промокод не найден",
  "promo code is expired": "Промокод истек",
  "promocode exhausted": "Количество активаций исчерпано",
};

export const parseAxiosError = (nativeError: unknown): [string, number?] => {
  const error = nativeError as AxiosError;
  console.error(error);
  // if (process.env.NODE_ENV !== "production") console.error(error);
  if (error.message === "Failed to fetch") return ["Ошибка сети"];

  //ДЛЯ ОПЛАТЫ ТАРИФА
  if(error.response.status === 402) {
    console.error(error.response?.data.message)
    return error.response?.data.message
  }

  if (error.response?.data) {
    const serverError = error.response.data as ServerError;
    let SEMessage;
    
    const status = error.response.status;
    if(status === 409 || status === 401 || status === 404) {
      const serverErrorMessage = error.response.data.message
      const translatedMessage = translateMessage[serverErrorMessage.toLowerCase() || ""]
      return [translatedMessage || "", serverError.statusCode];
    }

    // if (error.response.statusText) {
    //   return [error.response.statusText];
    // }

    // if ("statusCode" in serverError) {
    //   SEMessage = serverError?.message.toLowerCase() || "";
    // }
    // if ("error" in serverError && !("statusCode" in (error.response.data as ServerError))) {
    //   SEMessage = serverError.error.toLowerCase() || "";
    // }
    const translatedMessage = translateMessage[SEMessage || ""]?.toLowerCase();
    if (translatedMessage !== undefined) SEMessage = translatedMessage;
    return [SEMessage || "", serverError.statusCode];
  }

  if (isAxiosError(error)) {
    switch (error.code) {
      case "ERR_NETWORK":
        return ["Ошибка сети"];
      case "ERR_CANCELED":
        return ["Неизвестная ошибка сервера."];
      case "ERR_BAD_REQUEST":
        return ["Слишком много запросов"];
    }
  }

  switch (error.status) {
    case 404:
      return ["Не найдено.", error.status];

    case 403:
      return ["Доступ ограничен.", error.status];
      
    case 429:
      return ["Слишком частые запросы", error.status];

    case 401:
      return ["Ошибка авторизации.", error.status];

    case 500:
      return ["Внутренняя ошибка сервера.", error.status];

    case 503:
      return ["Сервис недоступен.", error.status];

    default:
      return ["Неизвестная ошибка сервера."];
  }
};
