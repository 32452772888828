import { CssBaseline, ThemeProvider, Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ruRU } from "@mui/x-date-pickers/locales";
import App from "./App";
import { SnackbarProvider, closeSnackbar } from "notistack";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import "./index.css";
import lightTheme from "./utils/themes/light";
import { SWRConfig } from "swr";
import { BrowserRouter } from "react-router-dom";
import moment from "moment";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import CloseIcon from "@icons/CloseBold";

import type { SnackbarKey } from "notistack";
import { CheckFastlink } from "@ui_kit/CheckFastlink";
import { ErrorBoundary } from "react-error-boundary";
import { handleComponentError } from "./utils/handleComponentError";

moment.locale("ru");
polyfillCountryFlagEmojis();
const localeText =
  ruRU.components.MuiLocalizationProvider.defaultProps.localeText;

const snackbarAction = (snackbarId: SnackbarKey) => (
  <Button
    onClick={() => closeSnackbar(snackbarId)}
    sx={{
      minWidth: "auto",
      padding: "0px",
      "&:hover": { backgroundColor: "transparent" },
    }}
  >
    <CloseIcon />
  </Button>
);

const ApologyPage = () => <div><p>Что-то пошло не так</p></div>

const root = createRoot(document.getElementById("root")!);

root.render(
  <SWRConfig
    value={{
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }}
  >
    <DndProvider backend={HTML5Backend}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale="ru"
        localeText={localeText}
      >
        <ThemeProvider theme={lightTheme}>
          <BrowserRouter>
            <SnackbarProvider
              SnackbarProps={{ onTouchStart: () => closeSnackbar() }}
              action={snackbarAction}
              preventDuplicate={true}
              style={{ backgroundColor: lightTheme.palette.brightPurple.main }}
            >
              <CssBaseline />

              <ErrorBoundary
                FallbackComponent={ApologyPage}
                onError={handleComponentError}
              >
                <App />
              </ErrorBoundary>
              <CheckFastlink />
            </SnackbarProvider>
          </BrowserRouter>
        </ThemeProvider>
      </LocalizationProvider>
    </DndProvider>
  </SWRConfig>,
);
