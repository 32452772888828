import { sendTicketMessage } from "@/api/ticket";
import { setTicketData, useTicketStore } from "@/stores/ticket";
import { useUserStore } from "@root/user";
import { createTicket, sendFile as sendFileRequest } from "@api/ticket";
import { enqueueSnackbar } from "notistack";

interface SelectSendingMethod {
    messageField: string;
    isSnackbar?: boolean;
    systemError?: boolean;
  
  }
export const selectSendingMethod = async ({messageField, isSnackbar = true, systemError = false}: SelectSendingMethod) => {
    console.log("click")
  const user = useUserStore.getState().user?._id;
  const ticket = useTicketStore.getState()[user ? "authData" : "unauthData"];
  console.log(ticket)

  console.log("click 2")
  let successful = false;
   if (!(window.location.hostname == 'localhost'  && systemError)) { //предупреждать о системных ошибках вне локалхост
      if (!ticket.sessionData?.ticketId) {
        console.log("autorisated 2")
        const [data, createError] = await createTicket(
          messageField,
          Boolean(user),
          systemError,
        );

        if (createError || !data) {
          successful = false;

          if (isSnackbar) enqueueSnackbar(createError);
        } else {
          successful = true;

          setTicketData({ ticketId: data.Ticket, sessionId: data.sess });
        }

      } else {
        const [_, sendTicketMessageError] = await sendTicketMessage(
          ticket.sessionData?.ticketId,
          messageField,
          systemError,
        );
        successful = true;

        if (sendTicketMessageError) {
          successful = false;
          if (isSnackbar) enqueueSnackbar(sendTicketMessageError);
        }
      }
   }
  return successful;
}